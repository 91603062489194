import styled from '@emotion/styled';
import { useRef } from 'react';
import type { FC, ReactNode } from 'react';
import { useInView } from 'react-intersection-observer';
import { Breakpoint, NoMotionPreference, UserAgentSupport } from '@pafcloud/style';
import { NavButton } from './NavButton';
import { navigate } from './horizontalNav';
import type { NavDirection } from './horizontalNav';

const Spacer = styled.div({
  display: 'block',
  width: 'calc(var(--padding-inline) - var(--safe-gap))',
  flexGrow: 0,
  flexShrink: 0,
  visibility: 'hidden',
  flexBasis: 'auto',

  // Remove this when we drop support for iOS14.
  [UserAgentSupport.IOS14AndBelow]: {
    margin: 0, // Reset fake gap.
    width: 'var(--padding-inline)',
  },
});

const Container = styled.div({
  '--padding-inline': 'var(--full-width-margin)',
  position: 'relative',
  width: '100%',
  isolation: 'isolate',
});

const Scrollable = styled.div({
  width: '100%',

  // Fix clipped shadows
  padding: '48px 0',
  margin: '-48px 0',

  // Scrolling behavior
  overflowX: 'auto',
  overscrollBehaviorInline: 'contain',
  scrollSnapType: 'x mandatory',
  scrollPadding: 'var(--padding-inline)', // Needed for snapping to work

  // Hide scrollbars
  scrollbarWidth: 'none',
  '::-webkit-scrollbar': {
    height: 0,
    width: 0,
    display: 'none',
  },

  // Overflow mask
  [Breakpoint.LaptopOrLarger]: {
    '--mask-transition-speed': '0.085s',
    [NoMotionPreference]: {
      transition: 'mask-size var(--mask-transition-speed) ease-in-out',
    },

    maskPosition: 'center',
    maskSize: 'calc(100% + var(--padding-inline) * 2)',
    maskImage: `
    linear-gradient(90deg,
      rgba(0, 0, 0, 0.1) 0px,
      rgba(0, 0, 0, 0.5) calc(var(--padding-inline) - 16px),
      rgb(0, 0, 0) var(--padding-inline),
      rgb(0, 0, 0) calc(100% - var(--padding-inline)),
      rgba(0, 0, 0, 0.5) calc(100% - var(--padding-inline) + 16px),
      rgba(0, 0, 0, 0.1) 100%
      );
    `,

    '*:hover > &': {
      maskSize: '100%',
      [NoMotionPreference]: {
        transition: 'mask-size var(--mask-transition-speed) ease-in-out',
      },
    },
  },
});

const Grid = styled.div({
  '--number-of-items': 1,
  '--gap': '8px',

  '--safe-gap': 'clamp(8px, var(--gap), 64px)',
  '--total-page-gutter': 'calc(var(--padding-inline) * 2)',
  '--total-gap-space': 'calc(var(--safe-gap) * (var(--number-of-items) - 1))',

  '--available-width': 'calc(100% - var(--total-page-gutter) - var(--total-gap-space))',
  '--item-width': `calc(var(--available-width) / max(1, var(--number-of-items)))`,

  userSelect: 'none',

  display: 'flex',
  flexDirection: 'row',
  alignItems: 'stretch', // make all items same height
  flexWrap: 'nowrap',
  gap: 'var(--safe-gap)',

  // Remove this when we drop support for iOS14.
  [UserAgentSupport.IOS14AndBelow]: {
    gap: 0,
  },

  width: '100%',

  // Snap to all items
  [`> *:not(${Spacer})`]: {
    flex: '1 0 var(--item-width)',
    scrollSnapAlign: 'start',
    minWidth: 0, // Fixes common flex "bug". Fixes issue where items grow too big and text-overflow: ellipsis doesn't work

    // Fake gap. Remove this when we drop support for iOS14.
    [UserAgentSupport.IOS14AndBelow]: {
      '& + *': {
        marginLeft: 'var(--safe-gap)',
      },
    },
  },

  // Set up number of items per breakpoint
  [Breakpoint.TabletOrLarger]: {
    '--gap': '16px',
    '--number-of-items': 1.5,
  },

  [Breakpoint.LaptopOrLarger]: {
    '--number-of-items': 2,
  },

  [Breakpoint.BigScreenOrLarger]: {
    '--number-of-items': 3,
  },

  [Breakpoint.HDScreenOrLarger]: {
    '--number-of-items': 4,
  },
});

type HorizontalScrollerProps = {
  children: ReactNode;
  className?: string;
};

export const HorizontalScroller: FC<HorizontalScrollerProps> = ({ children, className }) => {
  const scrollableRef = useRef<HTMLDivElement | null>(null);
  const gridRef = useRef<HTMLDivElement | null>(null);

  // If we need it there are polyfills
  const isMissingSupport = typeof IntersectionObserver === 'undefined';

  const leftSpacerRef = useInView({ threshold: 0.01, skip: isMissingSupport });
  const rightSpacerRef = useInView({ threshold: 0.01, skip: isMissingSupport });

  const onNavClicked = (direction: NavDirection) => {
    if (scrollableRef.current != null && gridRef.current != null) {
      navigate(direction, scrollableRef.current, gridRef.current);
    }
  };

  return (
    <Container>
      {!leftSpacerRef.inView && <NavButton direction="left" onClick={() => onNavClicked('left')} />}
      <Scrollable ref={scrollableRef}>
        <Grid className={className} ref={gridRef}>
          {/* 
              Add Spacers to provide scrollable padding inside the grid. 
              They are also used to notify when the nav buttons should be shown
          */}
          <Spacer ref={leftSpacerRef.ref} data-testid="left-spacer" />
          {children}
          <Spacer ref={rightSpacerRef.ref} data-testid="right-spacer" />
        </Grid>
      </Scrollable>
      {!rightSpacerRef.inView && <NavButton direction="right" onClick={() => onNavClicked('right')} />}
    </Container>
  );
};
