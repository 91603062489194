const getGridStyles = (grid: HTMLElement) => {
  const gridStyle = getComputedStyle(grid);

  return {
    itemsPerPage: parseInt(gridStyle.getPropertyValue('--number-of-items')) || 0,
    gap: parseFloat(gridStyle.getPropertyValue('--gap')) || 0,
  };
};

export type NavDirection = 'left' | 'right';

const clamp = (value: number, min: number, max: number) => {
  return Math.max(Math.min(value, max), min);
};

const getScroll = (direction: NavDirection, scrollable: HTMLElement, grid: HTMLElement) => {
  if (grid.childElementCount <= 2) {
    return 0;
  }

  const { itemsPerPage, gap } = getGridStyles(grid);

  const leftSpacer = grid.firstElementChild as HTMLElement;
  const rightSpacer = grid.lastElementChild as HTMLElement;

  const scrollWidth = grid.scrollWidth;
  // The right spacer adds an extra gap.
  const availWidth = scrollWidth - leftSpacer.offsetWidth - rightSpacer.offsetWidth - gap;

  // Slice away the spacers.
  const items = Array.prototype.slice.call(grid.children, 1, grid.childElementCount - 1);
  const itemCount = items.length;
  const itemWidth = (availWidth - itemCount * gap) / itemCount;

  if (itemWidth <= 0 && gap <= 0) {
    return 0;
  }

  const slotWidth = itemWidth + gap;

  // Narrow the scroll to the actual content by removing the initial spacer.
  const scrollLeft = Math.max(scrollable.scrollLeft - leftSpacer.offsetWidth, 0);
  const currentSlot = Math.floor(scrollLeft / slotWidth);

  // See how much of the slot that is scrolled away. Remove gap as we want the actual item pixels scrolled away.
  const scrolledPixels = Math.max(scrollLeft - (currentSlot * slotWidth - gap), 0);
  const scrolledQuotient = clamp(scrolledPixels / itemWidth, 0, 1);

  const fullItemsPerPage = Math.floor(itemsPerPage);
  const viewThreshold = 0.33;
  let slotJump = 0;

  if (direction === 'left') {
    slotJump = -(scrolledQuotient >= viewThreshold ? Math.max(fullItemsPerPage - 1, 0) : fullItemsPerPage);
  } else {
    slotJump = scrolledQuotient >= viewThreshold ? fullItemsPerPage + 1 : fullItemsPerPage;
  }

  const lastSlot = itemCount - 1;
  const targetSlot = clamp(currentSlot + slotJump, 0, lastSlot);
  const element = items[targetSlot];

  if (targetSlot === 0) {
    return 0;
  }

  if (targetSlot === lastSlot) {
    return scrollWidth;
  }

  return element.offsetLeft - gap;
};

export const navigate = (direction: NavDirection, scrollable: HTMLElement, grid: HTMLElement) => {
  const position = getScroll(direction, scrollable, grid);

  scrollable.scrollTo({
    left: position,
    behavior: 'smooth',
  });
};
